import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TGModal from "../../../../../shared/tg-modal";
import styles from "./checkin-flights-popup.module.scss";
import { TGRadio } from "../../../../../shared/tg-radio";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { DateToDayConverter } from "../../../../../utils/helper";
import TGIcon from "../../../../../shared/tg-icon";

interface Props {
  handleClose(): void;
  flightDetails: any;
}

const FlightCheckinDetails = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [selectedFlight, setSelectedFlight] = useState<any>();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [selected, setSelected] = useState(1);

  const handleRadioChange = (e: any, index: any) => {
    setSelectedFlight(e?.id);
  };

  return (
    <>
      <div className={styles["flight-details"]}>
        <TGModal
          customOffCanvasClass="checkinPadding"
          handleClose={props.handleClose}
          show={true}
          heading={"select flight to checkin"}
          buttonPresent={true}
          buttonLabel={"Confirm"}
          headingClass="checkinHeader"
          customModalClass={styles.customModalClass}
        >
          <div className={styles["card-container"]}>
            {props?.flightDetails?.map((flightsList: any, flightIndex: any) => (
              <div className={styles["flight-content"]}>
                <div className={styles["flight-selection-button"]}>
                  <TGRadio
                    name="checkin-flight-lists"
                    options={[
                      {
                        id: flightIndex + 1,
                        label: [],
                      },
                    ]}
                    onChange={(e: any) => {
                      handleRadioChange(e, flightIndex);
                      setSelected(flightIndex + 1);
                    }}
                    isBorder={false}
                    selected={selected}
                  />
                </div>
                <div className={styles["flight-container"]}>
                  <span className={styles["booking-ref-text"]}>
                    {t("label_booking_ref")}:{" "}
                    <span className={styles["booking-pnr-text"]}>
                      {flightsList?.flightNumKey}
                    </span>
                  </span>
                  <div className={styles["flight-details-container"]}>
                    <div className={styles["time-city-container"]}>
                      <div className={styles["city-code-date-container"]}>
                        <div className={styles["city-content"]}>
                          <span className={styles["city-code-text"]}>
                            {flightsList?.departureCityCode}{" "}
                            {flightsList?.departureTime}
                          </span>
                          <span className={styles["date-text"]}>
                            {DateToDayConverter(flightsList?.departureDate)}
                          </span>
                        </div>
                      </div>
                      <div className={styles["airport-terminal-container"]}>
                        <span className={styles["airport-terminal-text"]}>
                          {flightsList?.departureAirportName}
                        </span>
                        <span className={styles["airport-terminal-text"]}>
                          {t("label_redemption_termainal")}{" "}
                          {flightsList?.departureTerminal}
                        </span>
                      </div>
                    </div>
                    <div className={styles["flight-icon-container"]}>
                      <div className={styles["flight-icon-content"]}>
                        <span className={styles["flight-line"]}></span>
                        <TGIcon
                          icon="flight-icon"
                          fillColor=""
                          size=""
                        ></TGIcon>
                        <span className={styles["flight-line"]}></span>
                      </div>
                      <div className={styles["duration-container"]}>
                        <span className={styles["trip-duration-text"]}>
                          {flightsList?.duration}
                        </span>
                      </div>
                    </div>
                    <div className={styles["time-city-destination-container"]}>
                      <div className={styles["destination-container"]}>
                        <div className={styles["city-code-date-container"]}>
                          <div className={styles["city-content"]}>
                            <span className={styles["city-code-text"]}>
                              {flightsList?.arrivalCityCode}{" "}
                              {flightsList?.arrivalTime}
                            </span>
                            <span className={styles["date-text"]}>
                              {DateToDayConverter(flightsList?.arrivalDate)}
                            </span>
                          </div>
                        </div>
                        <div className={styles["airport-terminal-container"]}>
                          <span className={styles["airport-terminal-text"]}>
                            {flightsList?.arrivalAirportName}
                          </span>
                          <span className={styles["airport-terminal-text"]}>
                            {t("label_redemption_termainal")}{" "}
                            {flightsList?.arrivalTerminal}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </TGModal>
      </div>
    </>
  );
};

export default FlightCheckinDetails;
