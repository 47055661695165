import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./footer.module.scss";
import { AppStoreData } from "../../../../OSCI/src/models/models";
import { RootState } from "../../../../OSCI/src/slice/RootReducer";
import { config } from "../../../../config/global";
import {
  getAppStoreDataFailure,
  getAppStoreDataSuccess,
} from "../../../../OSCI/src/slice/appStoreSlice";
import {
  getSocialMediaDataFailure,
  getSocialMediaDataSucess,
} from "../../../../OSCI/src/slice/socialMediaSlice";

const Footer = () => {
  const dispatch = useDispatch();

  const appStore = useSelector(
    (state: RootState) => state?.osciReducer?.app?.appStoreDetails
  );

  const socialMedia = useSelector(
    (state: RootState) => state.osciReducer.socialMedia.social
  );

  const APPSTORE_URL = config.TEAMSITE_APPSTORE_API;
  const SOCIAL_URL = config.TEAMSITE_SOCIAL_PLATFORMS;

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: APPSTORE_URL,
      isTeamsite: true,
      successAction: getAppStoreDataSuccess,
      errorAction: getAppStoreDataFailure,
    });
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: SOCIAL_URL,
      isTeamsite: true,
      successAction: getSocialMediaDataSucess,
      errorAction: getSocialMediaDataFailure,
    });
  }, [APPSTORE_URL, SOCIAL_URL, dispatch]);

  // Application Store Section
  function AppStore() {
    return appStore?.map((store: AppStoreData, idx: number) => (
      <div className={styles.appStoreContainer} key={idx}>
        <div className={styles.appStoreWrapper}>
          <a href={store.appStoreLink}>
            <img src={store.appStore} alt="App Store" />
          </a>
          <a href={store.playStoreLink}>
            <img src={store.playStore} alt="Play Store" />
          </a>
        </div>
      </div>
    ));
  }

  // Social Media Application Section
  function SocialPlatforms() {
    return (
      <div
        className={styles.socialMediaContainer}
        key={socialMedia.footerSectionId}
      >
        <div className={styles.social}>
          <span className={styles.platformText}>
            {socialMedia.footerSectionName}
          </span>
          <div className={styles.platform}>
            {socialMedia.platforms?.map((z: any, id: any) => (
              <a href={z.iconLink} key={id}>
                <img src={`/assets/${z?.iconId}.svg`} alt={z?.iconName} />
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.footerContainer}>
      <div className={`${styles.downloadContainer} global-content-padding`}>
        <div className={styles.downloadContainerWrapper}>
          <div className={styles.mainContent}>
            <span className={styles.downloadText}>download the app now</span>
            <span className={styles.subText}>
              Easily manage, track, and modify your trip on the go. All your
              plans in one comprehensive digital travel companion.
            </span>
          </div>
          <AppStore />
          <SocialPlatforms />
        </div>
      </div>
      <div className={`${styles.copyrightWrapper} global-content-padding`}>
        <div className={styles.copyrightInnerWrapper}>
          <span className={styles.copyrightText}>
            Copyright © 2024 Thai Airways International Public Company Limited
            (THAI). All rights reserved.
          </span>
          <div className={styles.starAllianceImg}></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
