import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TGModal from "../../../../../shared/tg-modal";
import styles from "./cancel-checkin.module.scss";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import TGIcon from "../../../../../shared/tg-icon";
import { cancelCheckinRequest } from "../../../../../DAPI/src/slice/cancelCheckin/cancelCheckinSlice";
import { RootState } from "../../../../../store";
import TGButtonVariants from "../../../../../shared/tg-button-variants";
import { useLocation, useNavigate } from "react-router-dom";
import passengerStyle from "../select-passengers/select-passengers.module.scss";
import TGCheckBox from "../../../../../shared/tg-checkbox";

const CancelCheckin = () => {
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  let isMobile = useScreenSize()?.deviceSize?.width < 768;
  const location = useLocation();
  const cancelCheckinData = location.state || {};
  const firstCheckedinPnr = Object.keys(cancelCheckinData?.checkedInGroup);
  const firstCheckedKey = firstCheckedinPnr[0];
  const [allCheckBox, setAllCheckBox] = useState(false);
  const [disableCheckin, setDisableCheckin] = useState(true);
  const checkedInPassengers = cancelCheckinData?.checkedInPassengers;
  const [selectedPassengers, setSelectedPassengers] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    console.log("Cancelled");
  };

  const handleCheckIn = () => {
    if (selectedPassengers?.length > 0) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const handleAllChange = (e: any, data: any) => {
    setAllCheckBox(!allCheckBox);
    Object.keys(cancelCheckinData?.checkedInGroup).forEach((key: any) => {
      cancelCheckinData?.checkedInGroup[key].forEach((item: any) => {
        item.isChecked = !allCheckBox;
      });
    });
    let newArray: any[] = [];
    Object.keys(cancelCheckinData?.checkedInGroup).forEach((key: any) => {
      cancelCheckinData?.checkedInGroup[key].forEach((item: any) => {
        if (item.isChecked) {
          newArray.push(item);
        }
      });
    });
    setSelectedPassengers(newArray);
    if (newArray?.length > 0) {
      setDisableCheckin(false);
    } else {
      setDisableCheckin(true);
    }
  };

  const handleCheckinChange = (e: any, data: any, index: any) => {
    data.isChecked = e.target.checked;
    let newArray: any[] = [];
    Object.keys(cancelCheckinData?.checkedInGroup).forEach((key: any) => {
      cancelCheckinData?.checkedInGroup[key].forEach((item: any) => {
        if (item.isChecked) {
          newArray.push(item);
        }
      });
    });
    setSelectedPassengers(newArray);
    console.log("new array :", newArray);
    if (newArray?.length === checkedInPassengers?.length) {
      setAllCheckBox(true);
    } else {
      setAllCheckBox(false);
    }

    if (newArray?.length > 0) {
      setDisableCheckin(false);
    } else {
      setDisableCheckin(true);
    }
  };

  const renderCheckinButton = () => {
    return (
      <div className={passengerStyle["not-checked-in-container"]}>
        <div className={passengerStyle["not-checked-in-content"]}>
          <div className={passengerStyle["checked-in-bg"]}>
            <TGIcon icon="checked-in-tick-icon" fillColor="" size="" />
            <span className={passengerStyle["not-checked-in-text"]}>
              Checked In
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${passengerStyle.checkInBackgroundContainer} ${styles.topContainer} global-content-padding`}
      >
        <div className={passengerStyle["hero-container"]}>
          <div
            className={styles["hero-section"]}
            style={{
              gap:
                cancelCheckinData?.acceptance?.isAccepted && isDesktopView
                  ? "40px"
                  : isMobile && cancelCheckinData?.acceptance?.isAccepted
                  ? "20px"
                  : isMobile
                  ? "12px"
                  : "16px",
            }}
          >
            <span className={passengerStyle.selectPassengerText}>
              Select Passengers to Cancel Check In
            </span>
          </div>
          <div
            className={`${passengerStyle["passenger-section"]} ${styles.passengerBorder}`}
            style={{
              gap:
                (cancelCheckinData?.acceptance?.isAccepted && isDesktopView) ||
                (cancelCheckinData?.acceptance?.isPartial && isDesktopView)
                  ? "24px"
                  : (isMobile && cancelCheckinData?.acceptance?.isAccepted) ||
                    (cancelCheckinData?.acceptance?.isPartial && isMobile)
                  ? "16px"
                  : isMobile
                  ? "16px"
                  : "1p6x",
              padding:
                (cancelCheckinData?.acceptance?.isAccepted && isDesktopView) ||
                (cancelCheckinData?.acceptance?.isPartial && isDesktopView)
                  ? "32px 32px 24px 32px"
                  : (isMobile && cancelCheckinData?.acceptance?.isAccepted) ||
                    (cancelCheckinData?.acceptance?.isPartial && isMobile)
                  ? "24px 16px"
                  : isMobile
                  ? "16px"
                  : "24px",
            }}
          >
            <div className={passengerStyle["all-passenger-container"]}>
              <div className={passengerStyle["all-passenger-content"]}>
                <div className={passengerStyle["all-passenger-checkbox"]}>
                  <TGCheckBox
                    onChange={(e) => {
                      handleAllChange(e, "all");
                    }}
                    className={""}
                    customLabel={""}
                    checked={allCheckBox}
                  />
                  <span className={passengerStyle["all-passengers-text"]}>
                    All Passengers
                  </span>
                </div>
                <span className={passengerStyle["booking-ref-text"]}>
                  Booking Reference:{" "}
                  <span className={passengerStyle["pnr-text"]}>
                    {firstCheckedKey}
                  </span>
                </span>
              </div>
              <div className={passengerStyle["passenger-list-container"]}>
                {/* need to iterate passengers list */}
                {Object.keys(cancelCheckinData?.checkedInGroup)?.map(
                  (key: any) => {
                    const items = cancelCheckinData?.checkedInGroup[key];
                    return (
                      <>
                        {firstCheckedKey !== key && (
                          <div
                            className={passengerStyle["all-passenger-content"]}
                          >
                            <div
                              className={
                                passengerStyle["all-passenger-checkbox"]
                              }
                            ></div>
                            <span
                              className={passengerStyle["booking-ref-text"]}
                            >
                              Booking Reference:{" "}
                              <span className={passengerStyle["pnr-text"]}>
                                {key}
                              </span>
                            </span>
                          </div>
                        )}
                        {items?.map((data: any, index: any) => (
                          <div
                            className={passengerStyle["passenger-container"]}
                            key={index}
                          >
                            <div
                              className={
                                passengerStyle["passenger-info-container"]
                              }
                            >
                              {!isDesktopView && renderCheckinButton()}
                              <div
                                className={
                                  passengerStyle[
                                    "passenger-name-checkbox-container"
                                  ]
                                }
                              >
                                <TGCheckBox
                                  className={""}
                                  customLabel={""}
                                  onChange={(e) => {
                                    handleCheckinChange(e, data, index);
                                  }}
                                  checked={data?.isChecked}
                                />
                                <div
                                  className={
                                    passengerStyle["passenger-infant-name"]
                                  }
                                >
                                  <span
                                    className={
                                      passengerStyle["passenger-name-text"]
                                    }
                                  >
                                    {data?.firstName} {data?.lastName}{" "}
                                    {data?.title}
                                  </span>
                                  {!isDesktopView &&
                                    Object.keys(data?.infant).length > 0 && (
                                      <span
                                        className={
                                          passengerStyle["passenger-name-text"]
                                        }
                                      >
                                        {data?.infant?.firstName}{" "}
                                        {data?.infant?.lastName}{" "}
                                        {data?.infant?.title} (Infant)
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div
                                className={
                                  passengerStyle["seat-baggage-container"]
                                }
                              >
                                <div
                                  className={passengerStyle["seat-container"]}
                                >
                                  <TGIcon
                                    icon="my-trip-seat-icon"
                                    fillColor=""
                                    size=""
                                  />
                                  <span className={passengerStyle["seat-text"]}>
                                    {data?.seatNum}
                                  </span>
                                </div>
                                <div
                                  className={
                                    passengerStyle["baggage-container"]
                                  }
                                >
                                  <TGIcon
                                    icon="my-trip-baggage-icon"
                                    fillColor=""
                                    size=""
                                  />
                                  <span
                                    className={passengerStyle["baggage-text"]}
                                  >
                                    {data?.adultBaggageWeight}{" "}
                                    {data?.adultBaggageUnit === "kilogram"
                                      ? " KG (Standard)"
                                      : ""}
                                    {!isDesktopView &&
                                      Object.keys(data?.infant).length > 0 && (
                                        <span
                                          className={
                                            passengerStyle["baggage-text"]
                                          }
                                        >
                                          {" + "}
                                          {
                                            data?.infant?.infantBaggageWeight
                                          }{" "}
                                          {data?.infant?.infantBaggageUnit ===
                                          "kilogram"
                                            ? " KG (Standard)"
                                            : ""}
                                        </span>
                                      )}
                                    {data?.additionalBaggage && (
                                      <span
                                        className={
                                          passengerStyle[
                                            "additional-baggage-text"
                                          ]
                                        }
                                      >
                                        {" "}
                                        +10 KG Additional Baggage Allowance
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              {isDesktopView && renderCheckinButton()}
                            </div>
                            {isDesktopView &&
                              Object.keys(data?.infant).length > 0 && (
                                <div
                                  className={passengerStyle["infant-container"]}
                                >
                                  <div
                                    className={`${
                                      !data?.checkedIn
                                        ? passengerStyle["infant-content"]
                                        : passengerStyle[
                                            "infant-checkedin-content"
                                          ]
                                    }`}
                                  >
                                    <span
                                      className={passengerStyle["infant-text"]}
                                    >
                                      {data?.infant?.firstName}{" "}
                                      {data?.infant?.lastName}{" "}
                                      {data?.infant?.title} (Infant)
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      passengerStyle["infant-baggage-content"]
                                    }
                                  >
                                    <div
                                      className={
                                        passengerStyle["infant-empty-baggage"]
                                      }
                                    >
                                      {" "}
                                    </div>
                                    <div
                                      className={
                                        passengerStyle[
                                          "infant-baggage-conatiner"
                                        ]
                                      }
                                    >
                                      <TGIcon
                                        icon="my-trip-baggage-icon"
                                        fillColor=""
                                        size=""
                                      />
                                      <span
                                        className={
                                          passengerStyle["baggage-text"]
                                        }
                                      >
                                        {data?.infant?.infantBaggageWeight}{" "}
                                        {data?.infant?.infantBaggageUnit ===
                                        "kilogram"
                                          ? " KG (Standard)"
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        ))}
                      </>
                    );
                  }
                )}
              </div>
            </div>
            <div className={styles["cancel-checkin-container"]}>
              <div className={styles["discard-button-container"]}>
                <div className={styles["discard-button-content"]}>
                  <span className={styles["discard-text"]}>Discard</span>
                </div>
              </div>
              <div className={styles["cancel-checkin-button-container"]} style={{cursor : disableCheckin ? 'inherit' : 'pointer'}}>
                <TGButtonVariants
                  label="Check in"
                  onClick={handleCheckIn}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                  bgColor={"#684B9B"}
                  padding={isDesktopView ? "12px" : "16px"}
                  textColor="#FFF"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  borderDisabled="0.791px solid #E3D4FE"
                  bgColorDisabled="#E3D4FE"
                  bgColorHover={"#684B9B"}
                  height="50px"
                  width="100%"
                  disabled={disableCheckin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <TGModal
          show={showModal}
          handleClose={handleCloseModal}
          buttonLabel="Ok"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <TGIcon fillColor={"none"} icon="cancel-checkin" />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {"Cancel Check-in?"}
                </span>
                <span className={styles.modalDescription}>
                  {"Are you sure you want to cancel this Check-in?"}
                </span>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <TGButtonVariants
                label={"Back"}
                disabled={false}
                display="flex"
                fillColor=""
                size=""
                align="left"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColor="#F5EFFF"
                padding="16px"
                textColor="#684B9B"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                bgColorHover="#F5EFFF"
                height="50px"
                width={isDesktopView ? "170px" : "100%"}
                onClick={handleCloseModal}
              />
              <TGButtonVariants
                label={"Confirm"}
                disabled={false}
                display="flex"
                fillColor=""
                size=""
                align="left"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                bgColor="#684B9B"
                padding="16px"
                textColor="#FFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                bgColorHover="#684B9B"
                height="50px"
                width={isDesktopView ? "170px" : "100%"}
                onClick={handleConfirm}
              />
            </div>
          </div>
        </TGModal>
      )}
    </>
  );
};

export default CancelCheckin;
