import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  
  checkinDetails: {},
  isLoading: false,
  hasData: false,
  checkInSuccess: false,
  error: "",
};

const fetchCheckInSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    retrieveJourneyRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.checkInSuccess = false;
      state.error = "";
    },
    retrieveJourneySuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkInSuccess = true;
      state.checkinDetails = action.payload;
    },
    retrieveJourneyFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      console.log("error:", state.error);
      state.checkInSuccess = false;
      state.checkinDetails = {};
    },
    resetJourneyDetails: (state: any) => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.checkInSuccess = false;
      state.hasData = false;
      state.error = "";
    },
  },
});

export const {
  retrieveJourneyRequest,
  retrieveJourneySuccess,
  retrieveJourneyFailure,
  resetJourneyDetails,
} = fetchCheckInSlice.actions;

export default fetchCheckInSlice.reducer;
