import { combineReducers } from "@reduxjs/toolkit";
import checkInSlice from "./checkin/checkinSlice"
import addPassengerSlice from "./checkin/addPassengerSlice";
const RootReducer: any = combineReducers({
    checkin: checkInSlice,
    addPassenger: addPassengerSlice
  });
  
  export type RootState = ReturnType<typeof RootReducer>;
  export default RootReducer;
  