import React, { useEffect } from "react";
import "./App.scss";
import Main from "./route";
import "./i18n";
import TGMainLoader from "./shared/tg-main-loader";
import { useClassObserver } from "./utils/hook/useClassObserver";
import useScreenSize from "./utils/hook/useScreenSize";
const App: React.FC = () => {
  const isOffcanvasExists = useClassObserver("offcanvas");
  const isDesktopModalExists = useClassObserver("desktop-modal");
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  useEffect(() => {
    if (isMobile) {
      if (isOffcanvasExists) {
        document.documentElement.style.setProperty("--html-position", "fixed");
        document.documentElement.style.setProperty("--body-overflow", "hidden");
      } else {
        document.documentElement.style.setProperty("--html-position", "");
        document.documentElement.style.setProperty("--body-overflow", "auto");
      }
    }
    if (!isMobile) {
      if (isDesktopModalExists) {
        document.documentElement.style.setProperty("--html-position", "fixed");
        document.documentElement.style.setProperty("--body-overflow", "hidden");
      } else {
        document.documentElement.style.setProperty("--html-position", "");
        document.documentElement.style.setProperty("--body-overflow", "auto");
      }
    }
  }, [isOffcanvasExists, isMobile, isDesktopModalExists]);
  return (
    <div className="App">
      <Main />
      <TGMainLoader />
    </div>
  );
};

export default App;
