// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hJ6PtUrol73XXaWVBVkQ {
  display: flex;
  height: 90px;
  padding: 12px 150px 8px 150px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid var(--gray-stroke, #DFE0EB);
  background: var(--gray-surface-invert, #FFF);
}
@media (max-width: 767px) {
  .hJ6PtUrol73XXaWVBVkQ {
    display: flex;
    height: 56px;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.wpHdJRor9gddZrQA4_Qk {
  display: flex;
  padding: 0px 150px;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-stroke, #DFE0EB);
  background: #FFF;
  mix-blend-mode: luminosity;
}

.W9vjXPIrLNPbAY_gzkQp {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

._Nr0wNqgxqgA4CAtrEI4 {
  display: flex;
  padding: 16px 24px 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  cursor: pointer;
}
._Nr0wNqgxqgA4CAtrEI4.IpLYQb4VfnO_5F7gMQiC {
  border-bottom: var(--numeric-4, 4px) solid var(--primary-stroke-strong, #684B9B);
  background: #FFF;
  mix-blend-mode: luminosity;
}`, "",{"version":3,"sources":["webpack://./src/DAPI/src/component/headers/headers.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,6BAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;EACA,oDAAA;EACA,4CAAA;AACJ;AAAI;EAVJ;IAWQ,aAAA;IACA,YAAA;IACA,iBAAA;IACA,sBAAA;IACA,8BAAA;IACA,uBAAA;EAGN;AACF;;AAAA;EACI,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,oDAAA;EACA,gBAAA;EACA,0BAAA;AAGJ;;AAAA;EACI,aAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;AAGJ;;AAAA;EACI,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AAGJ;AADI;EACI,gFAAA;EACA,gBAAA;EACA,0BAAA;AAGR","sourcesContent":[".thaiLogo {\n    display: flex;\n    height: 90px;\n    padding: 12px 150px 8px 150px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 12px;\n    border-bottom: 1px solid var(--gray-stroke, #DFE0EB);\n    background: var(--gray-surface-invert, #FFF);\n    @media (max-width: 767px) {\n        display: flex;\n        height: 56px;\n        padding: 8px 24px;\n        flex-direction: column;\n        justify-content: space-between;\n        align-items: flex-start;   \n    }\n}\n\n.tabContainer {\n    display: flex;\n    padding: 0px 150px;\n    align-items: flex-start;\n    gap: 30px;\n    align-self: stretch;\n    border-bottom: 1px solid var(--gray-stroke, #DFE0EB);\n    background: #FFF;\n    mix-blend-mode: luminosity;\n}\n\n.tabContainerWrapper {\n    display: flex;\n    align-items: flex-start;\n    gap: 24px;\n    flex: 1 0 0;\n}\n\n.tabName {\n    display: flex;\n    padding: 16px 24px 16px 0px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    flex: 1 0 0;\n    cursor: pointer;\n\n    &.activeTab {\n        border-bottom: var(--numeric-4, 4px) solid var(--primary-stroke-strong, #684B9B);\n        background: #FFF;\n        mix-blend-mode: luminosity;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thaiLogo": `hJ6PtUrol73XXaWVBVkQ`,
	"tabContainer": `wpHdJRor9gddZrQA4_Qk`,
	"tabContainerWrapper": `W9vjXPIrLNPbAY_gzkQp`,
	"tabName": `_Nr0wNqgxqgA4CAtrEI4`,
	"activeTab": `IpLYQb4VfnO_5F7gMQiC`
};
export default ___CSS_LOADER_EXPORT___;
