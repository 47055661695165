import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  addPassengersData: {},
  isLoading: false,
  hasData: false,
  addPaxSuccess: false,
  error: "",
};

const fetchAddPassengerSlice = createSlice({
  name: "addPassenger",
  initialState,
  reducers: {
    addPassengerRequest: (state: any, action: PayloadAction<any>) => {
      state.isLoading = true;
      state.addPaxSuccess = false;
      state.error = "";
    },
    addPassengerSuccess: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.addPaxSuccess = true;
      state.addPaxDetails = action.payload;
    },
    addPassengerFailure: (state: any, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      console.log("error:", state.error);
      state.addPaxSuccess = false;
      state.addPaxDetails = {};
    },
    resetAddPassengerDetails: (state: any) => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.addPaxSuccess = false;
      state.hasData = false;
      state.error = "";
    },
  },
});

export const {
  addPassengerRequest,
  addPassengerSuccess,
  addPassengerFailure,
  resetAddPassengerDetails,
} = fetchAddPassengerSlice.actions;

export default fetchAddPassengerSlice.reducer;
