import { call, put, takeLatest } from "redux-saga/effects";
import {
  retrieveJourneyRequest,
  retrieveJourneySuccess,
  retrieveJourneyFailure,
} from "../../slice/checkin/checkinSlice";
import api from "../../../../config/api";
import { config } from "../../../../config/global";
import { PayloadAction } from "@reduxjs/toolkit";
import i18n from "../../../../i18n";

const fetchRetrieveJourneyData = async (retrieveCheckIn: any) => {
  const RETRIVE_CHECKIN_API_URL = config.MY_TRIPS_CHECK_IN_API;
  const data = {
    mode: "no-cors",
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Accept-Language": `${i18n.language}`,
      "Content-Type": "application/json",
    },
    loader: true,
  };
  let retrieveeJourneyPayload = {
    ref: retrieveCheckIn.pnr,
    lastName: retrieveCheckIn.lastName,
  };
  const response = await api.post(
    `${RETRIVE_CHECKIN_API_URL}`,
    retrieveeJourneyPayload,
    data
  );
  return response;
};

function* getRetrieveJourneyData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(fetchRetrieveJourneyData, payload);
    sessionStorage.setItem("checkintoken", response?.headers?.accesstoken);
    yield put(retrieveJourneySuccess(response.data));
  } catch (error: any) {
    yield put(retrieveJourneyFailure(error));
  }
}

export function* watchRetrieveJourneyData(): any {
  yield takeLatest(retrieveJourneyRequest.type, getRetrieveJourneyData);
}
