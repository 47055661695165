import React from "react";
import { Navbar } from "react-bootstrap";
import TGIcon from "../../../../shared/tg-icon";
import styles from "./headers.module.scss";

const Headers: React.FC = () => {
  const renderThaiLogo = () => {
    return (
      <Navbar.Brand className={styles["thaiLogo"]} role="button" tabIndex={0}>
        <div
          className="d-flex align-items-center gap-1"
          onClick={() => window.location.replace(window.location.origin)}
        >
          <TGIcon
            icon="thai-header-logo"
            fillColor=""
            size={window.innerWidth <= 767 ? "32px" : "48px"}
          />
          <TGIcon
            icon="thai-header-text"
            fillColor=""
            size={window.innerWidth <= 767 ? "32px" : "48px"}
          />
        </div>
      </Navbar.Brand>
    );
  };

  return <div>{renderThaiLogo()}</div>;
};

export default Headers;
